import { useEffect, useState } from "react";
import {
  BookOpen,
  ChevronLeft,
  ChevronRight,
  Package,
  ShoppingBag,
  XCircle,
} from "react-feather";
import { NavLink } from "react-router-dom";
import DashboardLogo from "./logo/Dashboard";
import CompetitionLogo from "./logo/Competition";
import ReferralsLogo from "./logo/Referrals";
import PlansLogo from "./logo/Plans";
import SettingsLogo from "./logo/Settings";
import LogoutLogo from "./logo/Logout";
import ProfileCircleLogo from "./logo/ProfileCircle";
import LogoutAlertDialog from "../LogoutAlertDialog";
import useAuth from "../../utils/customHooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSidebarState,
  toggleSidebarState,
} from "../../utils/store/slice/sidebarState";
import "./style.scss";
import { OverlayTrigger, Popover } from "react-bootstrap";
import SupportLinks from "./components/SupportLinks";
import CupLogo from "./logo/Cup";
import ChildSelect from "../ChildSelect";

const popover = (
  <Popover id="popover-basic">
    <Popover.Body className="popover-support-link">
      <SupportLinks />
    </Popover.Body>
  </Popover>
);

const SideNavigation = () => {
  const [sidebarState, setSidebarState] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { logoutUser } = useAuth();

  const isSidebarOpen = useSelector(selectSidebarState);
  const sidebarStateDispatch = useDispatch();

  const changeSidebarWidth = () => {
    isSidebarOpen
      ? sidebarStateDispatch(toggleSidebarState(false))
      : sidebarStateDispatch(toggleSidebarState(true));
  };

  useEffect(() => {
    !isSidebarOpen ? setSidebarState("close") : setSidebarState("open");
  }, [isSidebarOpen]);

  const hideSidebarOnMobile = () => {
    const w = window.innerWidth;
    if (w <= 600) {
      sidebarStateDispatch(toggleSidebarState(false));
    }
  };

  return (
    <div
      className={`sidebar-container ${sidebarState} ${sidebarState}-shadow `}
    >
      <div className="app-logo">
        <NavLink to="/">
          {sidebarState === "open" ? (
            <img
              className="full-logo"
              src="/assets/app-logo.svg"
              alt="wizkids carnivals"
            />
          ) : (
            <img
              className="small-logo"
              src="/assets/app-logo-small.png"
              alt="wizkids carnivals"
            />
          )}
        </NavLink>
      </div>
      <div className="link-container px-2 d-flex d-md-none">
        <ChildSelect />
      </div>
      <div
        className={`link-container d-flex flex-column justify-content-center ${sidebarState}`}
      >
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/dashboard"
        >
          <div className="link-content">
            <div className="icon">
              <DashboardLogo />
            </div>
            Dashboard
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/kids-premier-league"
        >
          <div className="link-content position-relative">
            <div className="icon">
              <CupLogo />
            </div>
            KPL contest{" "}
            <span
              className={`new-tag ${isSidebarOpen ? "" : "collapsed-new-tag"}`}
            >
              New
            </span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/my-competitions"
        >
          <div className="link-content">
            <div className="icon">
              <CompetitionLogo />
            </div>
            {sidebarState === "open" ? "My competitions" : "Competitions"}
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/my-magazines"
        >
          <div className="link-content position-relative">
            <div className="icon">
              <BookOpen className="feather-icon" />
            </div>
            Magazines{" "}
            <span
              className={`new-tag ${isSidebarOpen ? "" : "collapsed-new-tag"}`}
            >
              New
            </span>
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/my-referrals"
        >
          <div className="link-content">
            <div className="icon">
              <ReferralsLogo />
            </div>
            {sidebarState === "open" ? "My referrals" : "Referrals"}
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/my-shipments"
        >
          <div className="link-content">
            <div className="icon">
              <Package className="feather-icon" />
            </div>
            Shipments
          </div>
        </NavLink>
        {/* <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/my-bookings"
        >
          <div className="link-content">
            <div className="icon">
              <ShoppingBag className="feather-icon" />
            </div>
            My Bookings
          </div>
        </NavLink> */}
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/upgrade-plan"
        >
          <div className="link-content">
            <div className="icon">
              <PlansLogo />
            </div>
            Upgrade plan
          </div>
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? "active-link-class" : "")}
          onClick={hideSidebarOnMobile}
          to="/account/settings"
        >
          <div className="link-content">
            <div className="icon">
              <SettingsLogo />
            </div>
            Settings
          </div>
        </NavLink>
        {sidebarState === "open" ? (
          <div className="support-button">
            <img src="/assets/girl-call-support.png" alt="support" />
            <div className="support-buttons d-flex align-items-center p-2">
              <div className="pt-2 ps-2">Support</div>
              <SupportLinks />
            </div>
          </div>
        ) : (
          <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <div role="button" className="link-content">
              <div className="icon" style={{ padding: "16px" }}>
                <ProfileCircleLogo />
              </div>
              <span className="dark-blue-text div-link-text">Support</span>
            </div>
          </OverlayTrigger>
        )}
      </div>
      <button onClick={() => setShowModal(true)} className="logout-button">
        <div className={`button-content ${sidebarState}`}>
          <div className="icon">
            <LogoutLogo />
          </div>
          <span className="dark-blue-text div-link-text">Logout</span>
        </div>
      </button>
      <button
        onClick={changeSidebarWidth}
        className="toggle-button d-none d-md-block"
      >
        {sidebarState === "open" ? <ChevronLeft /> : <ChevronRight />}
      </button>
      {sidebarState === "open" && (
        <button
          onClick={changeSidebarWidth}
          className="toggle-button-mobile d-block d-md-none"
        >
          <XCircle />
        </button>
      )}
      <LogoutAlertDialog
        show={showModal}
        onHide={() => setShowModal(false)}
        logoutUser={logoutUser}
      />
    </div>
  );
};

export default SideNavigation;
