import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Home from "../pages/home";
import AuthLayout from "./layout-routes/auth-lr";
import Loading from "../components/Loading";
import AccountRouter from "./layout-routes/account-lr";
import AuthGuard from "./layout-routes/account-lr/AuthGuard";
import NotFoundScreen from "../components/NotFoundScreen";
import AppToast from "../components/AppToast";

const LoginOrRegister = lazy(() => import("../pages/auth/loginOrRegister"));

const BasicUserDetail = lazy(() =>
  import("../pages/account/basicUserDetailsForm"),
);
const BillingDetails = lazy(() =>
  import("../pages/payment/billingDetailsForm"),
);
const PaymentPage = lazy(() => import("../pages/payment"));
const PaymentLinkRedirector = lazy(() =>
  import("../pages/payment/components/PaymentLinkRedirector"),
);

const CompetitionDetailPage = lazy(() => import("../pages/competitionDetails"));
const ResultsPage = lazy(() => import("../pages/results"));

const ImageViewerPage = lazy(() => import("../pages/imageViewer"));

const PaymentForm = lazy(() => import("../pages/payment/form"));
const PaymentVerificationRequestFrom = lazy(() =>
  import("../pages/payment/verification-request-form"),
);

const QualificationResultWall = lazy(() =>
  import("../pages/qualification-result-wall"),
);

const KPLQualificationSurveyForm = lazy(() =>
  import("../pages/kpl-qualifiers-survey"),
);

const PhoneAuthenticationForm = AuthLayout(<LoginOrRegister />);
const BasicUserDetailsForm = AuthLayout(<BasicUserDetail />);
const BillingDetailsForm = AuthLayout(<BillingDetails />);

const ScrollToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ScrollToTop>
          <div className="h-100 w-100">
            <AppToast />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="authentication"
                element={<PhoneAuthenticationForm />}
              />
              <Route
                path="account/basic-details"
                element={<AuthGuard component={<BasicUserDetailsForm />} />}
              />
              <Route path="account/*" element={<AccountRouter />} />
              <Route path="payment">
                <Route
                  path="billing-details"
                  element={<BillingDetailsForm />}
                />
                <Route
                  path="checkout/:contestName/:contestId/:amount"
                  element={<PaymentLinkRedirector />}
                />
                <Route path=":contestId/checkout" element={<PaymentPage />} />
                <Route path="form/:paymentId" element={<PaymentForm />} />
                <Route
                  path="verification-request-form"
                  element={<PaymentVerificationRequestFrom />}
                />
              </Route>
              <Route path="competition">
                <Route
                  path="details/:competitionId"
                  element={<AuthGuard component={<CompetitionDetailPage />} />}
                />
              </Route>
              <Route path="poster-viewer" element={<ImageViewerPage />} />
              <Route path="results/:partnerDomain" element={<ResultsPage />} />
              <Route
                path="qualifications/:KPLId"
                element={<QualificationResultWall />}
              />
              <Route
                path="/qualifications/survey/:KPLId"
                element={<KPLQualificationSurveyForm />}
              />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </div>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
