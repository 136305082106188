import axios from 'axios'
import axiosConfig from './axiosConfig'
import { PaymentCode, PaymentFormDetails, PaymentMethod, PaymentType, SalesAdvisor } from 'models';
import { PaymentVerificationFormState } from 'pages/payment/verification-request-form/utils/paymentVerificationFormState';

const baseURL = process.env.REACT_APP_BASE_URL

export const PaymentService = {
  initiatePayment: (paymentDetails: any) =>
    axios.post(`${baseURL}/payment/initiate`, paymentDetails, axiosConfig()),

  paymentSuccessful: (details: any, paymentId: string) =>
    axios.put(
      `${baseURL}/payment/success/${paymentId}`,
      details,
      axiosConfig()
    ),

  paymentFailed: (details: any, paymentId: string) =>
    axios.put(`${baseURL}/payment/fail/${paymentId}`, details, axiosConfig()),

  getPaymentDetails: (paymentId: string) => axios.get(`${baseURL}/payment/payment_code/${paymentId}`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      const paymentType = res.data.data.payment_code_type;
      const data = res.data.data;

      if (paymentType === PaymentType.SUBSCRIPTION) {
        const paymentDetails: PaymentFormDetails = {
          paymentType: PaymentType.SUBSCRIPTION,
          agentId: data.agent_id || '',
          id: data.subscription_id,
          name: data.name || "Subscription",
          durationInMonths: 12, // TODO: change this data.duration_in_months
          amount: data.price_in_INR,
          originalAmount: data.original_price_in_INR,
          benefits: data.detailed_benefits,
          contestLevel: data.contest_level || '1',
        }
        return paymentDetails;
      } else if (paymentType === PaymentType.COMBO) {
        const paymentDetails: PaymentFormDetails = {
          paymentType: PaymentType.COMBO,
          agentId: data.agent_id || '',
          id: data.combo_code,
          name: data.combo_name || "Combo",
          discountPrice: data.discount,
          maximumContestants: data.combo_item_count,
          contestLevel: data.contest_level || '1',
        }
        return paymentDetails;
      } else {
        throw new Error("Invalid payment type");
      }

    } else {
      throw new Error(res.data.developer_message);
    }
  }).catch(err => {
    throw new Error(err);
  }),
  initiatePublicPayment: (paymentId: string, payload: any) => axios.post(`${baseURL}/payment/payment_code/${paymentId}/initiate_payment`, payload, axiosConfig()),

  getPaymentMethods: () => axios.get(`${baseURL}/payment/payment_methods`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data.map((data: any) => ({
        id: data.id,
        name: data.name,
        description: data.description
      } as PaymentMethod))
    } else {
      throw new Error(res.data.developer_message);
    }
  }).catch(err => {
    throw new Error(err);
  }),

  getPaymentCodes: () => axios.get(`${baseURL}/payment/payment_code?payment_code_type=non_razorpay`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data.map((data: any) => ({
        paymentCode: data.payment_code,
        paymentCodeType: data.payment_code_type,
        contestCount: data.contest_count,
        contestLevel: data.contest_level
      } as PaymentCode))
    } else {
      throw new Error(res.data.developer_message);
    }
  }),

  getSalesAdvisors: () => axios.get(`${baseURL}/sales/sales_person`, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data.map((data: any) => ({
        email: data.email,
        mobile: data.mobile,
        name: data.name,
        salesPersonCode: data.sales_person_code,
        salesPersonId: data.sales_person_id
      }) as SalesAdvisor)
    } else {
      throw new Error(res.data.developer_message);
    }
  }),

  nonRazorpayPaymentVerificationFormSubmit: (payload: any) => axios.post(`${baseURL}/payment/non_razorpay_payment`, payload, axiosConfig()).then(res => {
    if (res.data.response_code === 80) {
      return res.data.data;
    } else {
      throw new Error(res.data.developer_message);
    }
  })

}
